@import '~antd/dist/antd.css';

body {
  overflow: hidden;
}

.titlelogo {
  float: left;
  width: 216px;
  height: 50px;
  background-image: url(https://ayasompo.com/wp-content/uploads/2019/09/logo_retina-300x52.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.component-layout {
  width: 100%;
  display: flex;
  /* justify-content: stretch; */
}

.component {
  /* border-left: 1px solid rgba(0, 0, 0, 0.06); */
  background-color: #ffffff;
  padding: 12px;
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.component.sidebar {
  width: 360px;
  padding: 0px;
}

.component .component-title {
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
}

.component.sidebar .component-title {
  margin: 12px;
}
